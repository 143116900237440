import React, { Component } from "react"
import FormMaker from "../../../components/formMaker"
import { Steps } from "intro.js-react"
import IntroWrapper from '../../../components/client-only-wrapper';

class GeneralDesignForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: {
        showProgress: true,
        dontShowAgain: true,
        showBullets: false,
        scrollToElement: false,
      },
      showUsers: true,
      initialStep: 0,
      steps: [
        {
          title: "General Design",
          intro:
            "This is for all other design requests: such as Posters, T-shirts, Signage for your office, Newsletter or maybe a promotional bag. Anthing not covered in the other options",
          tooltipClass: "intro-title",
        },
        {
          element: ".fileupload",
          title: "Upload File",
          intro:
            "Please do give us examples or any images you want used or images of the items.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: "#work_type",
          title: "Work Type",
          intro:
            "The more information you can give us here will help us to understand your needs.",
          position: "left",
          tooltipClass: "intro-title",
        },
        {
          element: ".submitLater",
          title: "NOTE:",
          intro:
            "You can save your progress here. Just remember that for it to be sent to head office you have to select the 'submit form' button",
          position: "top",
          tooltipClass: "intro-title",
        },
      ],
    }
  }

  toggleSteps = () => {
    this.setState(prevState => ({ stepsEnabled: !prevState.stepsEnabled }))
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }))
  }
  render() {
    const {
      stepsEnabled,
      steps,
      initialStep,
      options,
      scrollToElement,
    } = this.state
    console.log(this.props)
    return (
      <div>
        <IntroWrapper>
          <Steps
            scrollToElement={scrollToElement}
            options={options}
            enabled={stepsEnabled}
            steps={steps}
            initialStep={initialStep}
            onExit={this.onExit}
          />
        </IntroWrapper>

        <button
          className="fixed bottom-20 right-20 h-28 w-28 bg-brand-blue rounded-full text-white font-bold flex items-center justify-center shadow-lg z-50 font-medium"
          onClick={this.toggleSteps}
        >
          <span className="font-medium">Need Help?</span>
        </button>
        <FormMaker
          formId="6107f5a14f79900fe8ea9d3e"
          formType="general-designs"
          // id={`${"work_type"} ${"selectfiles"}`}
          submitLater="submitLater"
          fileupload="fileupload"
        />
      </div>
    )
  }
}

export default GeneralDesignForm
